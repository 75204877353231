import './../polyfills'
import LucaLogin from '../authentication/LucaLogin'
import '../libraries/fontawesome'
import 'bootstrap'
import { Application } from '@hotwired/stimulus'
import Flash_list_controller from '../controllers/flash_list_controller'
import User_invitations_controller from '../controllers/user_invitations_controller'

window.onload = function () {
  const luca = new LucaLogin()
  luca.listenToAuthChange()
}

// Register only controllers we need when logged out
let application = Application.start()
application.register('flash-list', Flash_list_controller)
application.register('user-invitations', User_invitations_controller)
